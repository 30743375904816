<body>
  <div class="headerContainer">
      <span  style="display:flex;align-items:center;">
          <img src="assets/images/smart_Logo.png" alt="smart-Logo" height="30px">
          | <span style="color:white;">&nbsp;REACH</span>
      </span>
    <span>
          <span class="dropdownContainer">
             <select name="language" class="dropdown" [(ngModel)]="selectedLanguage" (change)="onLanguageChange($event)">
                  <option class="overlay" *ngFor="let language of languages | keyvalue" [value]="language.key">
                    {{language.value}}
                  </option>
              </select>
          </span>
      </span>
  </div>
    <router-outlet></router-outlet>
</body>
