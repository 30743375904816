import {Component, Inject, LOCALE_ID} from '@angular/core';
import {Router} from "@angular/router";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'reach-webapp';
  selectedLanguage: string = 'en';
  languages: { [languageCode: string]: string } = {
    'en': 'English',
    'de': 'Deutsch'
  }


  constructor(private router: Router, private translate: TranslateService, @Inject(LOCALE_ID) locale: string) {
    this.applyLanguage(locale.substring(0, locale.indexOf('-')), locale);
  }

  onLanguageChange(selectedValue: any) {
    this.applyLanguage(selectedValue.target.value, selectedValue.target.value)
  }

  applyLanguage(selectedLanguage: string, locale : string) {
    if (this.languages[selectedLanguage]) {
      this.selectedLanguage = selectedLanguage;
    } else {
      console.log("No locales for [".concat(locale, "] available. Defaulting to english."))
      this.selectedLanguage = 'en'
    }
    this.translate.use(this.selectedLanguage);
    console.log('currentLanguage', this.translate.currentLang);
  }
}
