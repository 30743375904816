import {ModelData} from "./model/modelData";

export const availableModels: { [modelKey: string]: { [modelYear: string]: ModelData } } = {
  "smart #1": {
    "2022": {
      "parts": [
        "GeelyCoolant",
        "WindshieldFluid",
        "BrakeFluid",
        "TyreRepairEmulsion",
        "HVLithiumIonBatteryPack",
        "LeadAcidBattery",
        "NickelMetalHybrideBattery",
        "SirenBackupBattery",
        "AirbagInflatorDriver",
        "AirbagInflatorDMF5000",
        "AirbagInflatorDNB5000",
        "CABInflatorAssembly",
        "MicroGasGenerator",
        "MicroGasGeneratorCNGTMS2",
        "GearboxLubricantDEXRON",
        "GearboxLubricantIDEMITSU",
        "FireExtinguisher",
        "SensorTirePressure",
      ], "scips": [
        "245321034",
        "236503760",
        "235770784",
        "245356211",
        "245356172",
        "245321034-four-wheel",
      ]
    }
  }, "smart #3": {
    "2023": {
      "parts": [
        "GeelyCoolant",
        "WindshieldFluid",
        "BrakeFluid",
        "TyreRepairEmulsion",
        "HVLithiumIonBatteryPack",
        "LeadAcidBattery",
        "NickelMetalHybrideBattery",
        "SirenBackupBattery",
        "AirbagInflatorDriver",
        "AirbagInflatorDMF5000",
        "AirbagInflatorDNB5000",
        "CABInflatorAssembly",
        "MicroGasGenerator",
        "MicroGasGeneratorCNGTMS2",
        "GearboxLubricantDEXRON",
        "GearboxLubricantIDEMITSU",
        "FireExtinguisher",
        "SensorTirePressure",
        "ACCompressorOil"
      ], "scips": [
        "245321034",
        "236503760",
        "235770784",
        "245356211",
        "245356172",
        "245321034-four-wheel",
      ]
    }
  }
}
